import changeColorMenu from '../modules/changeColorMenu';
import popupEnchentes from '../modules/popupEnchentes';
import './owl.carousel.min.js'
popupEnchentes();
changeColorMenu();
$(document).ready(function() {

    $(".owl-one").owlCarousel({
        margin: 170,
        loop: true,
        autoplay: false,
        autoplayTimeout: 2000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 2,
                nav: true,
                margin: 12,
                navText: [
                    '<span class="owl-right_acess"><div class="left_arrow_owl">  </div></span>',
                    '<span class="owl-left_acess"><div class="right_arrow_owl">  </div></span>'
                ],
            },
            600: {
                items: 1,
                nav: true,
                margin: 20,
                navText: [
                    '<span class="owl-right_acess"><div class="left_arrow_owl">  </div></span>',
                    '<span class="owl-left_acess"><div class="right_arrow_owl">  </div></span>'
                ],
            },
            1250: {
                items: 3,
                nav: true,
                navText: [
                    '<span class="owl-right_acess"><div class="left_arrow_owl">  </div></span>',
                    '<span class="owl-left_acess"><div class="right_arrow_owl">  </div></span>'
                ],
            }
        }
    });
    $(".owl-two").owlCarousel({
        margin: 160,
        loop: true,
        autoplay: false,
        autoplayTimeout: 2000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 2,
                nav: true,
                margin: 12,
                navText: [
                    '<span class="owl-right_acess"><div class="left_arrow_owl">  </div></span>',
                    '<span class="owl-left_acess"><div class="right_arrow_owl">  </div></span>'
                ],
            },
            600: {
                items: 1,
                nav: true,
                margin: 20,
                navText: [
                    '<span class="owl-right_acess"><div class="left_arrow_owl">  </div></span>',
                    '<span class="owl-left_acess"><div class="right_arrow_owl">  </div></span>'
                ],
            },
            1250: {
                items: 3,
                nav: true,
                navText: [
                    '<span class="owl-right_acess"><div class="left_arrow_owl">  </div></span>',
                    '<span class="owl-left_acess"><div class="right_arrow_owl">  </div></span>'
                ],
            }
        }
    });
    $(".owl-three").owlCarousel({
        margin: 170,
        loop: false,
        autoplay: true,
        autoplayTimeout: 2000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 2,
                nav: true,
                margin: 12,
                navText: [
                    '<span class="owl-right_acess"><div class="left_arrow_owl">  </div></span>',
                    '<span class="owl-left_acess"><div class="right_arrow_owl">  </div></span>'
                ],
            },
            600: {
                items: 1,
                nav: true,
                margin: 20,
                navText: [
                    '<span class="owl-right_acess"><div class="left_arrow_owl">  </div></span>',
                    '<span class="owl-left_acess"><div class="right_arrow_owl">  </div></span>'
                ],
            },
            1250: {
                items: 3,
                nav: true,
                navText: [
                    '<span class="owl-right_acess"><div class="left_arrow_owl">  </div></span>',
                    '<span class="owl-left_acess"><div class="right_arrow_owl">  </div></span>'
                ],
            }
        }
    });
    $(".owl-four").owlCarousel({
        margin: 50,
        loop: false,
        autoplay: false,
        autoplayTimeout: 2000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 2,
                nav: true,
                margin: 12,
                navText: [
                    '<span class="owl-right_acess"><div class="left_arrow_owl">  </div></span>',
                    '<span class="owl-left_acess"><div class="right_arrow_owl">  </div></span>'
                ],
            },
            600: {
                items: 1,
                nav: true,
                margin: 20,
                navText: [
                    '<span class="owl-right_acess"><div class="left_arrow_owl">  </div></span>',
                    '<span class="owl-left_acess"><div class="right_arrow_owl">  </div></span>'
                ],
            },
            1250: {
                items: 3,
                nav: true,
                navText: [
                    '<span class="owl-right_acess"><div class="left_arrow_owl">  </div></span>',
                    '<span class="owl-left_acess"><div class="right_arrow_owl">  </div></span>'
                ],
            }
        }
    });
    const prevButtons = document.querySelectorAll('button.slick-prev.slick-arrow.slick-disabled');
    prevButtons.forEach(prevButton => {
        prevButton.setAttribute('aria-label', 'next');
    });
    const nextButtons = document.querySelectorAll('button.slick-next.slick-arrow');
    nextButtons.forEach(nextBtn => {
        nextBtn.setAttribute('aria-label', 'prev');
    })
    const owlPrev = document.querySelectorAll('button.owl-prev');
    const owlNext = document.querySelectorAll('button.owl-next');
    owlPrev.forEach(e => {
        e.setAttribute('aria-label', 'prev');
    })
    owlNext.forEach(e => {
        e.setAttribute('aria-label', 'next');
    })
    const dot = document.querySelectorAll('button.owl-dot');
    const dotActive = document.querySelectorAll('button.owl-dot.active');
    dot.forEach(e => {
        e.setAttribute('aria-label', 'dot');
    })
    dotActive.forEach(e => {
        e.setAttribute('aria-label', 'dot Active');
    })
});

