export default function changeColorMenu() {
  if ($(window).width() > 991) {
		let header = $('#header');
		let headerWhite = 'header--white';

		if ($('.slick-active .banner__iten').data('menucolor') === 1) {
			header.addClass(headerWhite);
		}

		$('.banner').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
			if ($('.banner__iten[data-slick-index="' + nextSlide + '"]').data('menucolor') === 1) {
				header.removeClass(headerWhite);
			} else {
				header.addClass(headerWhite);
			}
		});
	}
}